@use '../../styles/mixins' as mixins;

.columns-block {

	@include mixins.sectionStyles;

	&:has(.error-number-module) {

		background: radial-gradient(115.59% 70.41% at -14.19% 54.94%, rgba(0, 145, 90, 0.04) 0%, rgba(245, 251, 248, 0.482) 60.07%, rgba(255, 255, 255, 0.5) 100%);

		.bnpp-container {
			align-items: center;
		}
	}
	
	.bnpp-container {
		@include mixins.grid(400px);

		&>div>div.block-editor-block-list__layout {
			@include mixins.grid(400px);
		}

	}

	&.contained {

		.bnpp-container {
			padding: var(--wp--preset--spacing--60);
		}

	}

	&.dark {
		
		.download-module {

			.icon-wrapper {
				@include mixins.SVGDarkBg;
			}

			.content {

				h3 {
					color: var(--wp--preset--color--white);
				}

				img {
					filter: grayscale(1) brightness(0) invert(1);
				}
			}


		}

	}


}